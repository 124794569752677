<template>
  <div id="app">
    <van-nav-bar title="" :fixed="true" v-if="$route.name != 'login'" left-text="返回" left-arrow
      @click-left="onClickLeft" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss">
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

::v-deep .van-nav-bar__content {
  height: 92px;
}
</style>
